import React, {useState, useEffect, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';

function Sidebar(props) {
  let [unlocked, setUnlock] = useState(false);
  let {isMobile} = props;
  let barHeight = isMobile ? props.screenHeight - 74 : props.screenHeight;
  let widthCalc = isMobile ? 'calc(100vw - 250px)' : 'calc(100vw - 400px)';

  let history = useHistory();
  let handleOnClick = useCallback(() => {
    props.toggleSidebar();
    history.push('/setup'), [history];
  });

  //onClick={handleOnClick}

  let menu = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#555',
        width: isMobile ? 250 : 400,
        paddingTop: isMobile ? 0 : 20,
        height: barHeight,
      }}
    >
      <button className={'sideBarText'} style={{marginTop: 22}} onClick={() => props.showLogin('register')}>
        register
      </button>

      <button onClick={() => props.showLogin()} className={'sideBarText'} style={{marginTop: 12}}>
        login
      </button>

      <div
        style={{
          height: 0.5,
          backgroundColor: '#fbf7f5',
          width: 36,
          opacity: 0.2,
          marginTop: 14,
        }}
      />
    </div>
  );
  if (props.uid) {
    menu = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#555',
          width: isMobile ? 250 : 400,
          height: barHeight,
          paddingTop: 20,
        }}
      >
        {unlocked && (
          <button className={'sideBarText'} style={{marginTop: 20}} onClick={() => {}}>
            my lists
          </button>
        )}
        <button className={'sideBarText'} onClick={props.openSettings}>
          settings
        </button>
        <div
          style={{
            height: 0.5,
            backgroundColor: '#fbf7f5',
            width: 36,
            opacity: 0.2,
            marginTop: 14,
          }}
        />
        <button className={'sideBarText'} style={{marginTop: 8, opacity: 0.6, fontWeight: 100}} onClick={props.logout}>
          logout
        </button>
      </div>
    );
  }
  return (
    <div
      className={'sideBarMobile'}
      style={{
        position: 'absolute',
        left: 0,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        width: '100vw',
        transform: props.showSidebar ? 'translateX(0)' : `translateX(100vw)`,
        transition: 'transform 250ms ease-in-out',
        zIndex: 50,
        height: barHeight,
        zIndex: 150,
      }}
    >
      <div
        onClick={props.toggleSidebar}
        style={{
          width: widthCalc,
          height: barHeight,
        }}
      ></div>
      <div>
        {isMobile && <div onClick={props.toggleSidebar} style={{display: 'flex', flex: 1, height: 74}} />}
        {menu}
      </div>
    </div>
  );
}
export default Sidebar;
