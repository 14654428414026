import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, useLocation} from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';
import Favicon from 'react-favicon';

import Home from './Home';
import favicon from './imgs/favicon-96x96.png';
import './styles.css';

const App = () => (
  <Router>
    <Favicon url={favicon} />
    <Route path="/:dir">
      <HomeWithParams />
    </Route>
    <Route exact path="/">
      <HomeWithParams />
    </Route>
  </Router>
);

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function HomeWithParams() {
  let query = useQuery();
  let continueUrl = query.get('continueUrl');
  let mode = query.get('mode');
  let oobCode = query.get('oobCode');

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  console.log('isMobile', isMobile);

  return (
    <CookiesProvider>
      <Home
        isMobile={isMobile}
        screenWidth={width}
        screenHeight={height}
        continueUrl={continueUrl}
        oobCode={oobCode}
        mode={mode}
      />
      ;
    </CookiesProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));

module.hot.accept();
