import React, {useState, useEffect, useCallback} from 'react';

let CardSearch = ({addNewCard, cardInput = '', onCardInput, cardGetError, searchLoading}) => {
  return (
    <>
      <form className={'flexRow'} onSubmit={e => addNewCard(e, cardInput)} style={{paddingBottom: 6}}>
        <div className={'flexRow'} style={{alignItems: 'center'}}>
          <input
            type="text"
            className={'smsSetup'}
            style={{
              width: 150,
              height: 34,
              marginLeft: 9,
              paddingLeft: 4,
            }}
            value={cardInput}
            onChange={e => onCardInput(e.target.value)}
            placeholder={'cyclonic rift'}
          />
        </div>
        {searchLoading ? (
          <div style={{position: 'relative', width: 42, height: 42}}>
            <div className={'arc-hider'} />
          </div>
        ) : (
          <input
            type="submit"
            className={'smsSubmit'}
            style={{
              height: 42,
              width: 42,
              backgroundColor: '#999',
              color: '#fff',
              fontSize: 23,
              marginLeft: 2,
            }}
            value="+"
          />
        )}
      </form>
      {cardGetError && <div style={{color: 'red', fontWeight: 300}}>{cardGetError}</div>}
    </>
  );
};

export default CardSearch;
