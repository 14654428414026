import React, {useState, useRef} from 'react';
import {getAuth, createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import Axios from 'axios';

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

let EmailRegister = ({loginLoadingEmail, cardArr}) => {
  let [showSent, updateSent] = useState(false);
  let [emailInput, updateEmailInput] = useState('');
  let [passwordInput, updatePasswordInput] = useState('');
  let [loading, setLoading] = useState(false);
  let [emailError, updateEmailError] = useState(false);
  let [passwordError, updatePasswordError] = useState(false);

  let emailRegister = event => {
    event.preventDefault();

    updateEmailError(false);
    updatePasswordError(false);

    setLoading(true);
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, emailInput, passwordInput)
      .then(async userCredential => {
        const user = userCredential.user;
        console.log('user', user);
        let uid = user.uid;
        let cardList = makeid(30);

        let userRes = await Axios.post('https://houseedh.com/newUser', {
          cardArr,
          uid,
          cardList,
        });

        let actionCodeSettings = {
          url: `https://houseedh.com/*${cardList}`,
        };

        sendEmailVerification(auth.currentUser, actionCodeSettings)
          .then(res => {
            console.log('sendEmailVerification res', res);
            updateSent(true);
            setLoading(false);
          })
          .catch(err => {
            console.log(' sendEmailVerificationerr', err);
            setLoading(false);
          });
      })
      .catch(error => {
        console.log('signup nowork', error.code);
        setLoading(false);
        if (error?.code?.includes('auth/invalid-email')) {
          updateEmailError('invalid email');
        }
        if (error?.code?.includes('auth/email-already-in-use')) {
          updateEmailError('email already in use');
        }
        if (error?.code?.includes('auth/weak-password')) {
          updatePasswordError('weak password');
        }
      });
  };

  return (
    <div className={'flexCol'}>
      <form className={'flexCol'} onSubmit={e => emailRegister(e)}>
        <div className={'flexRow'} style={{alignItems: 'center', marginBottom: 4}}>
          <input
            type="text"
            className={'smsSetup'}
            style={{
              width: 150,
              height: 34,
              paddingLeft: 4,
            }}
            value={emailInput}
            onChange={e => updateEmailInput(e.target.value)}
            placeholder={'maro@email.com'}
          />
        </div>
        <div className={'flexRow'} style={{alignItems: 'center'}}>
          <input
            type="text"
            className={'smsSetup'}
            style={{
              width: 150,
              height: 34,
              paddingLeft: 4,
            }}
            value={passwordInput}
            type="password"
            onChange={e => updatePasswordInput(e.target.value)}
            placeholder={'*******'}
          />
          {loading ? (
            <div style={{position: 'relative', width: 42, height: 42}}>
              <div className={'arc-hider'} />
            </div>
          ) : (
            <div>
              <input
                type="submit"
                className={'smsSubmit'}
                style={{
                  height: 42,
                  width: 42,
                  backgroundColor: '#999',
                  color: '#fff',
                  fontSize: 20,
                  marginLeft: 2,
                }}
                value="→"
              />
            </div>
          )}
        </div>
      </form>
      {showSent && <div style={{marginTop: 4}}>verification sent</div>}
      {emailError && <div style={{marginTop: 4}}>{emailError}</div>}
    </div>
  );
};
export default EmailRegister;
