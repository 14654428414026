import React, {useState, useRef} from 'react';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import Axios from 'axios';

let EmailLogin = ({showCode, loginLoadingEmail, cardArr}) => {
  let [showSent, updateSent] = useState(false);
  let [emailInput, updateEmailInput] = useState('');
  let [passwordInput, updatePasswordInput] = useState('');
  let [loading, setLoading] = useState(false);
  let [emailError, updateEmailError] = useState(false);
  let [passwordError, updatePasswordError] = useState(false);

  let emailLogin = event => {
    event.preventDefault();
    updateEmailError(false);
    updatePasswordError(false);
    setLoading(true);

    emailInput = emailInput.replaceAll(' ', '');
    const auth = getAuth();
    signInWithEmailAndPassword(auth, emailInput, passwordInput)
      .then(userCredential => {
        const user = userCredential.user;
        //user.sendEmailVerification();
        console.log('isEmailVerified', user.emailVerified);
        setLoading(false);
        //this.props.setUserId(user.id);
      })
      .catch(error => {
        console.log('signin nowork', error, error?.code);
        if (error?.code?.includes('auth/invalid-email')) {
          emailError('invalid email');
        }
        if (error?.code?.includes('auth/email-already-in-use')) {
          emailError('email already in use');
        }
        if (error?.code?.includes('auth/weak-password')) {
          emailError('weak password');
        }
        if (error?.code?.includes('auth/user-not-found')) {
          emailError('user not found');
        }
        if (error?.code?.includes('auth/wrong-password') || error?.message?.includes('INVALID_PASSWORD')) {
          emailError('incorrect password');
        }
        if (error?.code?.includes('auth/too-many-requests')) {
          emailError('too many requests');
        }
      });
  };

  return (
    <div className={'flexCol'}>
      <form className={'flexCol'} onSubmit={e => emailLogin(e)}>
        <div className={'flexRow'} style={{alignItems: 'center', marginBottom: 4}}>
          <input
            type="text"
            className={'smsSetup'}
            style={{
              width: 150,
              height: 34,
              paddingLeft: 4,
            }}
            value={emailInput}
            onChange={e => updateEmailInput(e.target.value)}
            placeholder={'maro@email.com'}
          />
        </div>
        <div className={'flexRow'} style={{alignItems: 'center'}}>
          <input
            type="text"
            className={'smsSetup'}
            style={{
              width: 150,
              height: 34,
              paddingLeft: 4,
            }}
            value={passwordInput}
            type="password"
            onChange={e => updatePasswordInput(e.target.value)}
            placeholder={'*******'}
          />
          {loading ? (
            <div style={{position: 'relative', width: 42, height: 42}}>
              <div className={'arc-hider'} />
            </div>
          ) : (
            <div>
              <input
                type="submit"
                className={'smsSubmit'}
                style={{
                  height: 42,
                  width: 42,
                  backgroundColor: '#999',
                  color: '#fff',
                  fontSize: 20,
                  marginLeft: 2,
                }}
                value="→"
              />
            </div>
          )}
        </div>
      </form>
      {showSent && <div style={{marginTop: 4}}>verification sent</div>}
      {emailError && <div style={{marginTop: 4}}>{emailError}</div>}
    </div>
  );
};
export default EmailLogin;
