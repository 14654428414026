import React, {useState, useEffect, useCallback} from 'react';

let CardMenu = ({activateSect, activeSect}) => {
  return (
    <div className={'cardsMenu'}>
      <div
        className={'cardsMenuPick'}
        style={{
          backgroundColor: activeSect == 'battle' ? '#777' : '#fbf7f5',
          color: activeSect == 'battle' ? '#fff' : '#000',
        }}
        onClick={() => activateSect('battle')}
      >
        battle
      </div>
      <div
        className={'cardsMenuPick'}
        style={{
          backgroundColor: activeSect == 'low' ? '#777' : '#fbf7f5',
          color: activeSect == 'low' ? '#fff' : '#000',
        }}
        onClick={() => activateSect('low')}
      >
        low
      </div>
      <div
        className={'cardsMenuPick'}
        style={{
          backgroundColor: activeSect == 'mid' ? '#777' : '#fbf7f5',
          color: activeSect == 'mid' ? '#fff' : '#000',
        }}
        onClick={() => activateSect('mid')}
      >
        mid
      </div>
      <div
        className={'cardsMenuPick'}
        style={{
          backgroundColor: activeSect == 'high' ? '#777' : '#fbf7f5',
          color: activeSect == 'high' ? '#fff' : '#000',
        }}
        onClick={() => activateSect('high')}
      >
        high
      </div>
      <div
        className={'cardsMenuPick'}
        style={{
          backgroundColor: activeSect == 'cedh' ? '#777' : '#fbf7f5',
          color: activeSect == 'cedh' ? '#fff' : '#000',
        }}
        onClick={() => activateSect('cedh')}
      >
        cedh
      </div>
    </div>
  );
};

export default CardMenu;
