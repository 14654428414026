import React, {useState} from 'react';
import QRCode from 'react-qr-code';
import copyImgWhite from '../imgs/copyImgWhite.png';

let Top = ({
  uid,
  isMine,
  cardList,
  listName,
  clickHome,
  isMobile,
  toggleSidebar,
  showSidebar,
  screenWidth,
  screenHeight,
  psuedoUser,
}) => {
  let [showBig, setBig] = useState(false);
  let [showCopied, setShowCopied] = useState(false);

  console.log('uid && cardList', uid, cardList, isMine);
  if (!uid && (!cardList || cardList == '')) {
    cardList = 'global';
  }

  return (
    <div className={'top'}>
      <div className={'topMid'}>
        <div className={'topMidLeft'}>
          <div
            className={showBig ? 'qrBoxBig' : 'qrBox'}
            style={{
              width: showBig ? (isMobile ? screenWidth - 20 : 700) : 66,
              height: showBig ? 380 : 66,
              paddingTop: showBig ? 14 : 0,
              zIndex: 30,
            }}
            onClick={() => setBig(!showBig)}
          >
            <QRCode
              size={showBig ? 280 : 60}
              value={'https://www.houseedh.com/*' + cardList}
              bgColor={'#777'}
              fgColor={'#fff'}
            />
            {showBig && (
              <div
                onClick={e => {
                  e.stopPropagation();
                  navigator.clipboard.writeText('https://www.houseedh.com/*' + cardList);
                  setShowCopied(true);
                  setTimeout(() => {
                    setShowCopied(false);
                  }, '4000');
                }}
                className={'secondMenu qrHouse flexCenter flexRow'}
                style={{
                  color: '#fff',
                  zIndex: 34,
                  width: isMobile ? screenWidth - 20 : 700,
                  textAlign: 'center',
                  marginTop: 22,
                }}
              >
                {showCopied ? (
                  <div
                    style={{
                      width: 60,
                      border: '1px solid #ccc',
                      borderRadius: 5,
                      padding: 6,
                    }}
                  >
                    copied!
                  </div>
                ) : (
                  <>
                    <img src={copyImgWhite} style={{height: 32, width: 32, marginRight: 4}} />
                    {cardList}
                  </>
                )}
              </div>
            )}
            {(!isMine || !psuedoUser) && !showBig && (
              <div className={'secondMenu qrHouse'} style={{marginBottom: -21, fontSize: 14, opacity: 0.5}}>
                {cardList?.substring(0, 8)}
              </div>
            )}
          </div>
          {showBig && (
            <div
              onClick={() => setBig(!showBig)}
              style={{
                height: screenHeight - 420,
                width: screenWidth - 20,
                marginTop: 400,
                zIndex: 30,
                position: 'fixed',
              }}
            />
          )}
        </div>
        <div onClick={() => clickHome()} className={'topMidMid'}>
          <div>houseEdh</div>
          <div>ban list</div>
        </div>

        <div
          style={{
            width: isMobile ? 82 : 86,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <div>
            <button className={'settingsButton'} style={{marginTop: isMobile ? 28 : 28}} onClick={toggleSidebar}>
              {showSidebar ? (
                <div>
                  <div
                    className={'timesLeft'}
                    style={{
                      height: 1.5,
                      width: 25,
                      backgroundColor: '#999',
                      marginBottom: 4,
                      marginTop: 6,
                      marginRight: 1,
                    }}
                  />
                  <div
                    className={'timesRight'}
                    style={{
                      height: 1.5,
                      width: 25,
                      backgroundColor: '#999',
                      marginBottom: 4,
                      marginTop: -6,
                      marginRight: 1,
                    }}
                  />
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      height: 1.5,
                      width: 22,
                      backgroundColor: '#999',
                      marginBottom: 5,
                      marginRight: 1,
                    }}
                  />
                  <div
                    style={{
                      height: 1.5,
                      width: 22,
                      backgroundColor: '#999',
                      marginBottom: 5,
                      marginRight: 1,
                    }}
                  />
                  <div
                    style={{
                      height: 1.5,
                      width: 22,
                      backgroundColor: '#999',
                      marginRight: 1,
                    }}
                  />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top;
