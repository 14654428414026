import React, {Component} from 'react';
import Axios from 'axios';
import {withRouter} from 'react-router-dom';
import {initializeApp, getApps, getApp} from 'firebase/app';
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  onAuthStateChanged,
  signOut,
  applyActionCode,
} from 'firebase/auth';
import {getDatabase, ref, get, set} from 'firebase/database';

import Switch from 'react-input-switch';
import {withCookies, Cookies} from 'react-cookie';

import Sidebar from './home/Sidebar';
import Top from './home/Top';
import Login from './auth/Login';
import CardSearch from './home/CardSearch';
import CardMenu from './home/CardMenu';
import Register from './auth/Register';
import EmailRegister from './auth/EmailRegister';
import EmailLogin from './auth/EmailLogin';

const firebaseConfig = {
  apiKey: 'AIzaSyAjrsCTQL1aE_q5ub-oGnYCiHYPwVkG3Rg',
  authDomain: 'houseedh.firebaseapp.com',
  projectId: 'houseedh',
  storageBucket: 'houseedh.appspot.com',
  messagingSenderId: '891349640769',
  appId: '1:891349640769:web:87e02711db5a661b13c8c0',
  measurementId: 'G-GZ4SFYJLW4',
};

class Home extends Component {
  constructor(props) {
    super(props);
    let {cookies} = props;
    let dir = props.match?.params?.dir || '';

    let cookiesAll = cookies.getAll();
    let cookieArr = Object.values(cookiesAll);

    this.cardArrEmpty = {
      battle: [],
      low: [],
      mid: [],
      high: [],
      cedh: [],
    };

    this.state = {
      darkMode: false,
      cardArr: {},
      activeSect: 'mid',
      showCode: false,
      phoneInput: '',
      dir,
      activeCard: -1,
    };

    let apps = getApps().length;
    if (!apps) {
      const app = initializeApp(firebaseConfig);
    }
    this.inputs = [];
  }

  componentDidMount() {
    this.firebaseCaptcha();
    this.getAuth();
    this.getList();
  }

  getLocationIp = async user => {
    var options = {method: 'GET', url: 'https://ip.nf/me.json'};
    let ipLoc = await Axios.request(options).then(res => res.data);
    return ipLoc;
  };

  recordLoad = async uid => {
    let ipLoc = await this.getLocationIp();
    ipLoc = ipLoc?.ip?.ip?.replaceAll('.', '-');
    if (!ipLoc) {
      ipLoc = '000';
    }
    if (uid == '000') {
      uid = ipLoc;
    }
    this.setState({ip: ipLoc});
    const d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let timestamp = Date.now();
    const db = getDatabase();
    let analyticsPath = `analytics/views/${year}/${month}/${day}/${uid}/${timestamp}`;
    set(ref(db, analyticsPath), {timestamp});
  };

  getAuth = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      if (user) {
        const uid = user.uid;
        let {mode, oobCode} = this.props;
        if (mode == 'verifyEmail' && oobCode) {
          this.verifyEmail(user);
        }
        if (!user.emailVerified) {
          this.setState({completeEmailVerify: true});
          return;
        }
        this.getUserData(uid);
      } else {
        this.recordLoad('000');
        this.noUser();
      }
    });
  };

  verifyEmail = user => {
    let {oobCode, continueUrl} = this.props;

    let reloadUrl = '/';
    if (continueUrl) {
      reloadUrl = '/*' + continueUrl.split('/*')[1];
      console.log('reloadUrl', reloadUrl);
    }

    const auth = getAuth();
    applyActionCode(auth, oobCode)
      .then(res => {
        console.log('applyActionCode res', res);
        user.reload();
        this.props.history.push(reloadUrl);
        this.props.history.go(0);
      })
      .catch(err => {
        console.log('applyActionCode err', err);
        this.setState({verifyEmailError: true});
      });
  };

  noUser = () => {
    let {dir} = this.state;
    if (dir != '*global' && dir.substring(0, 1) == '*') return;
    let cardArr = {
      battle: [],
      low: [],
      mid: [
        {
          name: 'Mystical_Tutor',
        },
        {
          name: 'Imperial_Seal',
        },
        {
          name: 'Vampiric_Tutor',
        },
        {
          name: 'Demonic_Tutor',
        },
        {
          name: 'Worldly_Tutor',
        },
        {
          name: 'Sylvan_Tutor',
        },
        {
          name: 'Neoform',
        },
        {
          name: 'Gamble',
        },
        {
          name: 'Enlightened_Tutor',
        },
      ],
      high: [
        {
          name: 'Demonic_Consultation',
        },
        {
          name: 'Tainted_Pact',
        },
        {
          name: `Thassa's_Oracle`,
        },
      ],
      cedh: [
        {
          name: 'Time_Walk',
        },
        {
          name: 'Flash',
        },
      ],
    };
    this.setState({cardArr, listName: 'global'});
  };

  makeid = length => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  getList = async () => {
    let dir = this.props.match?.params?.dir || '';
    if (!dir.includes('*')) return;
    let cardList = dir.substring(1);
    console.log('cardList', cardList);
    if (cardList == 'global') {
      this.noUser();
      return;
    }
    let listRes = await Axios.get('https://houseedh.com/getList/' + cardList);
    //listName = listRes.data[0]?.cardList
    let cardArr = listRes.data?.userList[0]?.cardArr;
    this.setState({cardArr});
  };

  getUserData = async uid => {
    console.log('getUserData', uid);
    let userList = await Axios.get('https://houseedh.com/userList/' + uid);
    let cardArr = userList.data?.userList[0]?.cardArr;
    let myList = userList.data?.userList[0]?.cardList;

    let activeSect = 'mid';
    if (cardArr['battle']?.length > cardArr[activeSect]?.length) {
      activeSect = 'battle';
    }
    if (cardArr['low']?.length > cardArr[activeSect]?.length) {
      activeSect = 'low';
    }
    if (cardArr['high']?.length > cardArr[activeSect]?.length) {
      activeSect = 'high';
    }
    if (cardArr['cedh']?.length > cardArr[activeSect]?.length) {
      activeSect = 'cedh';
    }

    let dir = this.props.match?.params?.dir || '';
    if (dir.includes('*')) {
      this.setState({uid, myList, showAuth: false, showAuthRegiter: false, psuedoUser: false});
      return;
    }
    this.setState({uid, cardArr, myList, activeSect, showAuth: false, showAuthRegiter: false, psuedoUser: false});
  };

  firebaseCaptcha = () => {
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: response => {
          console.log('onSignInSubmit', response);
        },
      },
      auth,
    );
  };

  phoneNum = (event = null) => {
    if (event) {
      event.preventDefault();
    }
    const appVerifier = window.recaptchaVerifier;
    const auth = getAuth();

    let phoneNum = this.state.phoneInput;
    phoneNum = phoneNum.replaceAll('-', '').replaceAll(' ', '');
    phoneNum = `+1${phoneNum}`;

    this.setState({loginLoading: true});
    signInWithPhoneNumber(auth, phoneNum, appVerifier)
      .then(confirmationResult => {
        this.setState({showCode: true, confirmationResult, loginLoading: false}, () => {
          //this.inputs['smsCode'].focus();
          //
        });
      })
      .catch(err => {
        console.log('signInWithPhoneNumber err', err);
        this.setState({loginLoading: false});
      });
  };

  onPhoneChange = event => {
    let phoneInput = event.target.value;
    this.setState({phoneInput});
  };

  onSmsCode = async event => {
    let smsCode = event.target.value;
    this.setState({smsCode});
    let {psuedoUser, cardArr} = this.state;

    if (smsCode.length == 6) {
      let {confirmationResult} = this.state;
      confirmationResult.confirm(smsCode).then(async result => {
        const user = result.user;
        let uid = user.uid;
        if (psuedoUser) {
          let userRes = await Axios.post('https://houseedh.com/newUser', {
            cardArr,
            uid,
            cardList: this.makeid(30),
          });
        }
        this.setState({uid, showAuth: false, psuedoUser: false});
      });
    }
  };

  onCardInput = cardInput => {
    this.setState({cardInput});
  };

  sendCardBackend = async (cardUrl, cardName) => {
    let {uid, listId, activeSect, cardArr, myList} = this.state;

    var options = {
      method: 'POST',
      url: 'https://houseedh.com/newcard',
      data: {
        cardUrl,
        cardName,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await Axios.request(options).then(async res => {
      console.log('res', res);
      let cardResName = res.data?.cardName;
      let newCard = [{name: cardResName}];
      cardArr[activeSect] = newCard.concat(cardArr[activeSect]);
      cardArr[activeSect] = cardArr[activeSect]?.filter(a => a);

      if (uid) {
        Axios.post('https://houseedh.com/updateArr', {
          uid,
          cardArr,
          cardList: myList,
        });
      }
      this.setState({cardArr, cardInput: '', searchLoading: false});
    });
  };

  addNewCard = (event = null, cardInput) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({cardGetError: '', searchLoading: true, activeCard: -1});
    Axios.get('https://api.scryfall.com/cards/named?fuzzy=' + cardInput)
      .then(res => {
        let imgUrl = res.data?.image_uris?.normal || false;
        let nameUnderscore = res.data?.name?.replaceAll(' ', '_').replaceAll('/', '~');
        this.sendCardBackend(imgUrl, nameUnderscore);
      })
      .catch(err => {
        let errorString = 'no card';
        console.log('cardGet err', err?.response?.data);
        if (err?.response?.data?.type == 'ambiguous') [(errorString = 'too many results')];
        this.setState({cardGetError: errorString, searchLoading: false});
      });
  };

  activateSect = activeSect => {
    this.setState({activeSect, activeCard: -1});
  };

  logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this.setState({
          uid: null,
          showSidebar: false,
          myList: null,
          uid: null,
        });

        this.props.history.push('/');
      })
      .catch(error => {
        console.log('error logout', error);
      });
  };

  toggleSidebar = () => {
    let {showSidebar} = this.state;
    this.setState({showSidebar: !showSidebar});
  };

  clickCard = (a, k) => {
    let activeCard = k;
    if (this.state.activeCard == k) {
      activeCard = -1;
    }
    this.setState({activeCard});
  };

  removeCard = async (a, k) => {
    let {activeSect, activeCard, psuedoUser, cardArr, uid, myList} = this.state;
    if (psuedoUser) {
      let thisArr = cardArr[activeSect];
      thisArr = thisArr.filter((a, k) => k != activeCard);
      cardArr[activeSect] = thisArr;
      this.setState({cardArr, activeCard: -1});
      return;
    }
    if (uid) {
      let thisArr = cardArr[activeSect];
      thisArr = thisArr.filter((a, k) => k != activeCard);
      cardArr[activeSect] = thisArr;
      await Axios.post('https://houseedh.com/updateArr', {
        uid,
        cardArr,
        cardList: myList,
      });
      this.setState({cardArr, activeCard: -1});
      return;
    }
  };

  clickHome = () => {
    this.setState({showAuth: false});
    this.props.history.push('/');
    this.props.history.go(0);
  };

  createNew = () => {
    let {cardArr} = this.state;
    this.setState({psuedoUser: true, cardArr: this.cardArrEmpty, activeSect: 'mid', cardArrBak: cardArr});
  };

  backFromNew = () => {
    let {cardArrBak} = this.state;
    this.setState({psuedoUser: false, cardArr: cardArrBak, activeSect: 'mid'});
  };

  checkAnyFilled = cardArr => {
    if (cardArr['battle']?.length > 0) {
      return true;
    }
    if (cardArr['low']?.length > 0) {
      return true;
    }
    if (cardArr['mid']?.length > 0) {
      return true;
    }
    if (cardArr['high']?.length > 0) {
      return true;
    }
    if (cardArr['cedh']?.length > 0) {
      return true;
    }
    return false;
  };

  render() {
    let {
      cardArr = {},
      activeSect,
      showAuth,
      phoneInput,
      showCode,
      smsCode,
      listName,
      listId,
      cardInput,
      cardGetError,
      dir,
      uid,
      showSidebar,
      loginLoading,
      activeCard,
      psuedoUser,
      showRegister,
      searchLoading,
      myList,
      completeEmailVerify,
      showAuthRegiter,
    } = this.state;
    let {isMobile, screenWidth, screenHeight} = this.props;

    cardArr[activeSect] = cardArr[activeSect]?.filter(a => a);

    //let isMine = listArr?.filter(a => a.key == listId)[0];
    //console.log('isMine', isMine);

    let isMine = psuedoUser || false;

    let cardList = dir.split('*')[1] || '';
    cardList = cardList.split('/')[0];
    if (uid && (myList == cardList || cardList == '')) {
      isMine = true;
    }

    let newCard = (
      <div
        height={isMobile ? '474' : '279'}
        width={isMobile ? '340' : '200'}
        className={isMobile ? 'cardImgMobile' : 'cardImg'}
      >
        new
      </div>
    );

    let showtier = null;
    let chosenSect = cardArr[activeSect] || [];
    let anyFilled = this.checkAnyFilled(cardArr);

    let desktopAddHeight = 278 + 36 * chosenSect.length;
    let mobileAddHeight = 430 + 55 * chosenSect.length;

    if (chosenSect) {
      showtier = chosenSect.map((a, k) => {
        return (
          <div
            key={a.name}
            onClick={() => {
              console.log('isMine', isMine);
              isMine && this.clickCard(a, k);
            }}
            style={{position: 'relative'}}
          >
            <img
              src={'https://houseedh.com/' + a.name + '.png'}
              height={isMobile ? '474' : '307'}
              width={isMobile ? '340' : '220'}
              style={{backgroundColor: '#ddd'}}
              className={isMobile ? 'cardImgMobile' : 'cardImg'}
            />

            {activeCard == k && isMine && (
              <div
                className={isMobile ? 'removeCardMobile' : 'removeCard'}
                onClick={() => this.removeCard(a, k)}
                style={{
                  width: 50,
                  height: 30,
                  backgroundColor: '#fff',
                  top: 12,
                  right: 12,
                  paddingTop: 17,
                  borderRadius: 4,
                  border: '1px solid #555',
                  cursor: 'pointer',
                }}
              >
                <div
                  className={'timesLeft'}
                  style={{
                    height: 2,
                    width: 50,
                    backgroundColor: '#888',
                    marginBottom: 4,
                    marginTop: 6,
                    marginRight: 1,
                  }}
                />
                <div
                  className={'timesRight'}
                  style={{
                    height: 2,
                    width: 50,
                    backgroundColor: '#888',
                    marginBottom: 4,
                    marginTop: -6,
                    marginRight: 1,
                  }}
                />
              </div>
            )}
          </div>
        );
      });
    }

    return (
      <div className={'page'}>
        <Sidebar
          screenHeight={screenHeight}
          uid={uid}
          logout={this.logout}
          isMobile={isMobile}
          toggleSidebar={this.toggleSidebar}
          showSidebar={showSidebar}
          showLogin={method => {
            if (method == 'register') {
              this.setState({showAuthRegiter: true, showAuth: false, showSidebar: false});
            } else {
              this.setState({showAuth: true, showAuthRegiter: false, showSidebar: false});
            }
          }}
        />
        <div className={'signInCaptcha'} id="sign-in-button" />
        <Top
          dir={dir}
          listName={listName}
          clickHome={this.clickHome}
          isMobile={isMobile}
          clickHome={this.clickHome}
          toggleSidebar={this.toggleSidebar}
          showSidebar={showSidebar}
          cardList={isMine ? myList : cardList}
          isMine={isMine}
          screenWidth={screenWidth}
          screenHeight={screenHeight}
          uid={uid}
          psuedoUser={psuedoUser}
        />

        {showAuth && (
          <div className={'cardsBox'} style={{marginTop: 58}}>
            <div style={{width: 158, border: '0px solid #ccc', borderBottomWidth: 1, marginBottom: 18, marginRight: 44}}>
              Login
            </div>
            <Login
              phoneNum={this.phoneNum}
              phoneInput={phoneInput}
              onPhoneChange={this.onPhoneChange}
              loginLoading={loginLoading}
              onSmsCode={this.onSmsCode}
              showCode={showCode}
            />
            <div style={{marginBottom: 10, marginTop: 6, fontSize: 16}}>-- or --</div>
            <EmailLogin />
          </div>
        )}
        {showAuthRegiter && (
          <div className={'cardsBox'} style={{marginTop: 58}}>
            <div style={{width: 158, border: '0px solid #ccc', borderBottomWidth: 1, marginBottom: 18, marginRight: 44}}>
              Register
            </div>
            <Login
              phoneNum={this.phoneNum}
              phoneInput={phoneInput}
              onPhoneChange={this.onPhoneChange}
              loginLoading={loginLoading}
              onSmsCode={this.onSmsCode}
              showCode={showCode}
            />
            <div style={{marginBottom: 10, marginTop: 6, fontSize: 16}}>-- or --</div>
            <EmailRegister showCode={showCode} cardArr={cardArr} />
          </div>
        )}

        {!showAuth && !showAuthRegiter && (
          <div className={'cardsBox'}>
            {!uid && (
              <>
                {psuedoUser ? (
                  <div
                    onClick={() => this.backFromNew()}
                    className={'cardsMenuPick'}
                    style={{marginBottom: -37, marginRight: -192, border: '1px solid #aaa', padding: 6}}
                  >
                    {`< back`}
                  </div>
                ) : (
                  <div
                    onClick={() => this.createNew()}
                    className={'cardsMenuPick blueShadow'}
                    style={{marginBottom: -37, marginRight: -192, border: '1px solid #869cda', padding: 6, zIndex: 30}}
                  >
                    + new
                  </div>
                )}
              </>
            )}
            <CardMenu activateSect={this.activateSect} activeSect={activeSect} />

            {completeEmailVerify && (
              <div style={{padding: 20, border: '1px solid #aaa', marginTop: 20, color: '#333'}}>
                email verification sent
              </div>
            )}

            <div className={'showCards'} style={{border: completeEmailVerify ? 'solid 0px #aaa' : 'solid 1px #aaa'}}>
              {psuedoUser && !uid && (
                <div
                  className={'flexCol'}
                  style={{
                    color: '#333',
                    alignItems: 'center',
                    paddingBottom: 20,
                    minWidth: 280,
                  }}
                >
                  <div className={'flexCol'} style={{marginTop: 14, marginLeft: isMobile ? -4 : -8}}>
                    {!anyFilled && (
                      <>
                        <div style={{marginBottom: 12}}>{`1) ☝️ choose power level ☝️`}</div>
                        <div style={{marginBottom: 6}}>{`2) 🔎 add cards`}</div>
                      </>
                    )}
                    <div style={{alignSelf: anyFilled ? 'center' : 'normal', marginLeft: isMobile ? 0 : -2}}>
                      <CardSearch
                        addNewCard={this.addNewCard}
                        cardInput={cardInput}
                        onCardInput={this.onCardInput}
                        cardGetError={cardGetError}
                        searchLoading={searchLoading}
                      />
                    </div>
                    <div
                      style={{
                        minHeight: chosenSect.length > 0 ? (isMobile ? mobileAddHeight : desktopAddHeight) : 0,
                        marginLeft: isMobile ? 0 : 9,
                        zIndex: 20,
                      }}
                    >
                      {showtier}
                    </div>
                    <div
                      style={{
                        marginTop: 12,
                        alignSelf: anyFilled ? 'center' : 'normal',
                        marginLeft: isMobile ? -4 : 0,
                      }}
                    >{`3) 💾 save by logging in`}</div>
                    {showRegister ? (
                      <div
                        style={{
                          marginLeft: isMobile ? 0 : 9,
                          marginTop: 8,
                          alignSelf: anyFilled ? 'center' : 'normal',
                        }}
                      >
                        <Login
                          phoneNum={this.phoneNum}
                          phoneInput={phoneInput}
                          onPhoneChange={this.onPhoneChange}
                          loginLoading={loginLoading}
                          onSmsCode={this.onSmsCode}
                          showCode={showCode}
                          cardArr={cardArr}
                        />
                        <div style={{marginBottom: 10, marginTop: 6, fontSize: 16}}>-- or --</div>
                        <EmailRegister showCode={showCode} cardArr={cardArr} />
                      </div>
                    ) : (
                      <div
                        className={'flexRow flexCenter noBlue'}
                        style={{
                          width: 202,
                          height: 40,
                          backgroundColor: '#a6a6a6',
                          color: '#fff',
                          borderRadius: 4,
                          fontWeight: 300,
                          cursor: 'pointer',
                          marginTop: 6,
                          marginLeft: 6,
                          alignSelf: anyFilled ? 'center' : 'normal',
                        }}
                        onClick={() => this.setState({showRegister: true})}
                      >
                        save
                      </div>
                    )}
                    {false && (
                      <div
                        style={{
                          marginTop: 12,
                          alignSelf: anyFilled ? 'center' : 'normal',
                          marginLeft: isMobile ? -4 : 0,
                        }}
                      >{`4) 🔗 share to friend`}</div>
                    )}
                  </div>
                </div>
              )}
              {isMine && uid && (
                <div
                  style={{
                    alignSelf: anyFilled ? 'center' : 'normal',
                    marginLeft: isMobile ? 0 : -4,
                    marginRight: 3,
                    marginTop: 6,
                    marginBottom: 2,
                  }}
                >
                  <CardSearch
                    addNewCard={this.addNewCard}
                    cardInput={cardInput}
                    onCardInput={this.onCardInput}
                    cardGetError={cardGetError}
                    searchLoading={searchLoading}
                  />
                </div>
              )}
              {!psuedoUser && <>{showtier}</>}
            </div>
          </div>
        )}

        <div className={'footer'}></div>
      </div>
    );
  }
}

export default withCookies(withRouter(Home));
