import React, {useState, useRef} from 'react';

let Login = ({phoneNum, phoneInput, onPhoneChange, onSmsCode, smsCode, showCode, loginLoading}) => {
  const smsRef = useRef();
  return (
    <div className={'flexCol'}>
      <form className={'flexRow'} onSubmit={e => phoneNum(e)}>
        <div className={'flexRow'} style={{alignItems: 'center'}}>
          <input
            type="text"
            className={'smsSetup'}
            style={{
              width: 150,
              height: 34,
              paddingLeft: 4,
            }}
            value={phoneInput}
            onChange={onPhoneChange}
            placeholder={'404-555-1234'}
          />
        </div>
        {loginLoading ? (
          <div style={{position: 'relative', width: 42, height: 42}}>
            <div className={'arc-hider'} />
          </div>
        ) : (
          <input
            type="submit"
            className={'smsSubmit'}
            style={{
              height: 42,
              width: 42,
              backgroundColor: '#999',
              color: '#fff',
              fontSize: 20,
              marginLeft: 2,
            }}
            value="→"
          />
        )}
      </form>
      {showCode && (
        <div
          className={'noselect'}
          style={{
            borderRadius: 4,
            height: 41,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            borderWidth: 0,
            marginRight: 7,
            marginTop: 4,
          }}
        >
          <div
            className={'profileTextSub'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: 10,
            }}
          >
            sms code:
          </div>

          <input
            type="text"
            //ref={ref => (this.inputs['smsCode'] = ref)}
            ref={smsRef}
            className={'searchSubmitBoxCode smsSetup'}
            style={{
              width: 104,
              fontSize: 16.5,
              paddingLeft: 6,
            }}
            value={smsCode}
            onChange={onSmsCode}
            placeholder={'ØØØØØØ'}
          />
        </div>
      )}
    </div>
  );
};
export default Login;
